/* style.css */

/* Default Margin */
.margin-default {
    margin: 1em;
}

/* Min Width */
.min-width-default {
    min-width: 300px;
}

/* Default Card Title */
.card-title {
    display: flex;
    justify-content: space-between;
    align-items: center
}

/* Default Padding */
.padding-default {
    padding: 1em;
}

/* Default Banner Heading */
.banner-height {
    height: 9em !important;
}

/* Default Banner Font */
.banner-font {
    font-size: 4em !important;
}

/* App */
/* Layout header */
.app-layout-header {
    display: flex;
    gap: 1em;
    align-items: center;
}

/* Footer Image */
.app-footer-image {
    height: 9em;
}

/* Glossary Functions */
/* Page */
.glossary-functions-page {
    padding: 1em;
}

/* Header */
.glossary-functions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Edit Ingredients */
/* Ingredients Info Loading */
.ingredient-loading-screen {
    display: flex;
    min-height: 100vh;
    min-width: 100vw;
    justify-content: center;
    align-items: center;
}

/* SearchBar */
.search-bar {
    display: flex;
    align-items: center;
    margin: 1em;
    width: calc(100% - 2em);
    max-height: 150px;
    overflow-y: hidden;
}

.ant-select-selection-overflow {
    max-height: 150px;
    overflow-y: auto;
}

.ant-select-selection-overflow-item-suffix {
    flex-grow: 1 !important;
}

.ant-select-selection-search {
    width: 100% !important;
}

/* Search Container */
/* Header */
.search-container-header {
    display: flex;
}

/* Add Ingredient Button */
.search-container-add-ingredient {
    margin-top: 1em;
    margin-left: auto;
}

/* Download Content */
.search-container-download-file {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em
}

.ant-select-selector {
    width: 100%;
}

/* Not Found Page */
/* Page */
.not-found-page {
    padding: 2em;
}

/* Navigation */
/* Page */
.navigation-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Nav Bar */
.navigation-bar {
    flex: 1;
}

.associated-ingredients-list{
    padding-inline-start: 1em !important;
}

.search-results-container {
    margin-left: 2em;
    margin-top: 1em;
}

.red-text {
    color: red;
}
